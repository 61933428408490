import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Dialog, DialogContent, IconButton, DialogTitle, Divider, Grid, List, ListItemButton, MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, Autocomplete, TextField, FormControl, ListItem, ListItemText, DialogActions } from "@mui/material";
import React, { useEffect, useState } from "react";
import close from "../../asset/image/close.svg";
import _ from "lodash";
import { Info } from "@material-ui/icons";
import moment from "moment";
import CreatePlan from "./createPlan";
import { getCustomPlansList } from "../StarlinkAdmin/slice";
import CustomPlanList from "./customPlanList";
import { getPooledData, getParentPoolList } from "../StarlinkAdmin/slice";
var Loader = require("react-loader");


const EditActionsPopup = (props) => {
    const { loading, getAvailableProductsForAccount, open, setOpen, setConfirmDialog, confirmDialog, getCustomPlansList,  customPlansList, dpIds, getParentPoolList, parentPoolList } = props;


    const deviceActionsList = [
        "EXISTING PLANS",
        "CREATE NEW PLANS",
    ]?.filter(action => action);

    const getIdNameForList = (action) => {
        if (action == "EXISTING PLANS") {
            return 'actionType-enabled'
        }
        else if (action == "CREATE NEW PLANS") {
            return 'actionType-enabled'
        }
        return 'actionType-disabled';
    }

    const getDefaultActionType = (list) => {
        const actionTypes = list.map(action => {
            if (getIdNameForList(action) == 'actionType-enabled') {
                return action;
            } else {
                return '';
            }
        })
        return actionTypes?.filter(action => action != '')?.length > 0 ? actionTypes?.filter(action => action != '')?.[0] : deviceActionsList[0];
    }

    const [selectedAction, setSelectedAction] = useState(getDefaultActionType(deviceActionsList));
    const [planList, setPlanList] = useState([]);

    const updateActionValues = (action) => {
        switch (action) {

            case "STARLINK_ADMIN_DEVICES": {
                // handleEdit({
                //     serviceLineNumber: row.serviceLineNumber,
                //     nickname: row.serviceLineName
                // });
                break;
            }

            default:
        }
    }

    useEffect(() => {
        updateActionValues(selectedAction);
        //call get custom plans API
        getCustomPlansList({
            dpIds: dpIds,
            searchText: "",
            sortBy: "planSize",
            sortOrder: "asc",
            limit: 100,
            offset: 0
        })

        getParentPoolList({
            dpIds: dpIds,
            searchText: "",
            sortBy: "poolName",
            sortOrder: "desc",
            limit: 100,
            offset: 0,
            childPools: true
        })

    }, [selectedAction])

    useEffect(()=>{
        if(customPlansList && customPlansList.length){
           
            setPlanList(customPlansList)
        }
    },[customPlansList])

    const handleClose = () => {
        setOpen(false);
        setSelectedAction('');
    }

    const handleChangeActionType = (actionType) => {
        setSelectedAction(actionType);
        selectedAction != actionType && setConfirmDialog({ ...confirmDialog, subTitle: '', isOpen: false });
    }

    const editPlanActions = () =>{
        setSelectedAction('EXISTING PLANS')
    }

    return (
        <div className="Form">
            <Dialog
                disableEnforceFocus
                open={open}
                onClose={handleClose}
                aria-labelledby='form-dialog-title'
                fullWidth={true}
                maxWidth={'xl'}
                // className='config-deploy-inventory-popup menuScroll inventory-warehouse-register-pop-up inventory-edit-actions-container'
                className="menuScroll "
            >
                <div>
                    <DialogTitle className='create-default-config-title edit-actions-title'>
                        <div style={{display:"flex", justifyContent:"space-between"}}>
                        <div>
                            <span>Custom Plans</span>
                            <span className="selected-site-device starlink-admin-operations-popup-service-line-name">{`Plan Management`}</span>
                        </div>
                        <div>
                            {loading ? (
                                <div className="commonLoader_NewSummary wan-profile-interfaces-loader editActions-loader">
                                    <Loader radius={4} length={5} lines={10} width={2} color={"#264C86"} />
                                </div>
                            ) : null}
                            <img src={close} title="Close" onClick={handleClose} />
                        </div>
                        </div>
                    </DialogTitle>
                    <DialogContent className="edit-actions-content">
                        <Grid container spacing={1}>
                            <Grid xs={2} className="menuScroll device-border-right deployed-device-list" item>
                                <Grid className="device-data-body actions-device-data-body">
                                    <List>
                                        {deviceActionsList && deviceActionsList.map((identifier, index) => {
                                            return <ListItemButton id={getIdNameForList(identifier)} className='status-lists' style={identifier === selectedAction ? { background: 'rgba(244, 245, 250, 1)' } : { background: '#fff' }} key={`device-${index}`} selected={identifier === selectedAction}
                                                onClick={() => getIdNameForList(identifier) == 'actionType-enabled' && handleChangeActionType(identifier)}>
                                                <Typography className={identifier === selectedAction ? "font-status-pop-up selectedAction" : "font-status-pop-up NotselectedAction"}>{identifier}</Typography>
                                            </ListItemButton>
                                        })}
                                    </List>
                                </Grid>
                            </Grid>
                            <Grid item xs={10} className="menuScroll starlinkAdmin-edit-actions-cont">
                            <Grid className="device-data-body-customPlans edit-actions-data-body">
                                    <Stack className="sub-tab-parent" direction="column" spacing={1}>
                                        {selectedAction == "EXISTING PLANS" && getIdNameForList(selectedAction) == 'actionType-enabled' &&
                                            <CustomPlanList parentPoolList={parentPoolList} planList={planList} />
                                        }

                                        {selectedAction == "CREATE NEW PLANS" && getIdNameForList(selectedAction) == 'actionType-enabled' &&
                                            <>
                                            <CreatePlan parentPools={parentPoolList} setEditPlanActions={editPlanActions} />
                                            </>
                                        }
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ DialogContent>
                </div>
            </Dialog>


        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.starlinkAdmin.subcribingServiceLine,
    authReducer: state.authReducer,
    dpIds: state.starlinkCloud.dpIds,
    customPlansList: state.starlinkAdmin.customPlansList,
    parentPoolList: state.starlinkAdmin.parentPoolList
});

export default withRouter(
    connect(mapStateToProps, {
        getCustomPlansList,
        getParentPoolList
    })(EditActionsPopup)
);