import { withRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Accordion, AccordionSummary, AccordionDetails, Box } from "@mui/material";
import Download from '../../asset/image/DownloadUsage.svg';
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditActionsPopup from "./EditActionsPopup";
import "./index.css"
// import Tree from 'react-d3-tree';

function PlanHierarchy(props) {
    const { hierarchyData, parentPoolList } = props;

    const [openEditPopUp, setOpenEditPopUp] = useState(false);
    const [confirmDialog, setConfirmDialog] = useState({
        isOpen: false,
        title: "",
        subTitle: "",
        onConfirm: {},
    });

    return (
        <div className="plnaHierarchy-container">
            <Grid classes={{ root: 'starlink--base--mtb_07x starlink--base--flex--align--center starlink--base--flex--justify--space_between' }}>
                <Grid>
                    <Typography component="div" classes={{ root: 'starlink--base--font_1x' }}>
                        PLAN HIERARCHY
                    </Typography>
                </Grid>

                <Grid classes={{ root: 'starlink--base--flex starlink--base--flex--align--center' }}>
                    <Button size="small" variant="contained" className='starlink--button_contained--primary' onClick={() => { setOpenEditPopUp(true) }} >Manage Plan</Button>
                </Grid>
            </Grid>

            <TableContainer component={Paper} className="starlink--table_container">
                <Table sx={{ minWidth: 650 }} size="small" aria-label="starlink--service_accounts">
                    <TableHead>
                        <TableRow>
                            <TableCell >
                                <span className="font-wt-900">PLAN NAME</span>
                            </TableCell>
                            <TableCell>

                            </TableCell>
                        </TableRow>
                    </TableHead>
                </Table>
            </TableContainer>

            {hierarchyData && hierarchyData.length > 0 ? hierarchyData.map((plan, index) => (
    <Accordion key={"hierarchy" + index} className="planAccordion">
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel${index}-content`} className="accordionSummary">
            <Typography className="planName rootNode">
                {plan?.poolName}
            </Typography>
        </AccordionSummary>
        <AccordionDetails className="planAccordionDetails">
            <Table>
                <TableBody key={"h" + index} className="customTable">
                    {plan?.plans?.map((subPlan, subIndex) => (
                        <TableRow key={subIndex}>
                            <TableCell className="subAccordionDetails">
                                <Accordion style={{ boxShadow: "none", margin:"0px" }} className="plnaHierarchy-accordion">
                                    <AccordionSummary expandIcon={subPlan?.plans ? <ExpandMoreIcon style={{marginLeft:"20px" }} /> : null} className="accordionSummary childNode" >
                                        <Typography className={subPlan?.plans ? "" : "hierarchy-customPlan-padding"} >
                                            {subPlan?.planName} ({subPlan?.serviceLineCount})
                                        </Typography>
                                    </AccordionSummary>
                                    <AccordionDetails className="accordionDetails childNode">
                                        {subPlan?.plans && subPlan?.plans.length > 0 && subPlan?.plans?.map((customPlan, customIndex) => (
                                            <Typography key={customIndex} className="leafNode">
                                                {customPlan?.planName} ({customPlan?.serviceLineCount})
                                            </Typography>
                                        ))}
                                    </AccordionDetails>
                                </Accordion>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </AccordionDetails>
    </Accordion>
)) : 
<Typography display={"flex"} justifyContent={"center"}>
    No Data Available
</Typography>}



            {openEditPopUp ? <EditActionsPopup
                open={openEditPopUp}
                setConfirmDialog={setConfirmDialog}
                confirmDialog={confirmDialog}
                parentPoolList={parentPoolList}
                setOpen={() => { setOpenEditPopUp(false) }} />
                : null}


        </div>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {})(PlanHierarchy)
);