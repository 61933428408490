import { withRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import ServiceAccounts from "../StarlinkAdmin/ServiceAccounts";
import ServiceLines from "../StarlinkAdmin/ServiceLines";


interface StarlinkAccountsProps {
   
}

function StarlinkAccounts(props: StarlinkAccountsProps) {



    return (
       <>
       <ServiceAccounts />
       <ServiceLines  heading={"SERVICE LINES"}/>
       </>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {})(StarlinkAccounts)
);