import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Dialog, DialogContent, IconButton, DialogTitle, Divider, Grid, List, ListItemButton, MenuItem, Paper, Select, Box, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography, Autocomplete, TextField, FormControl, ListItem, ListItemText, DialogActions, Chip, Checkbox } from "@mui/material";
import React, { useEffect, useState } from "react";
import close from "../../asset/image/close.svg";
import _ from "lodash";
import { Info } from "@material-ui/icons";
import moment from "moment";
import OrgSelectionMultiple from "../OrgSelectionMultiple";
import { DateTimePicker, DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';
import momenttz from "moment-timezone";
import { createCustomPlan } from "../StarlinkAdmin/slice";
import { getDecodeURI } from "../../utils/util";
import { toast } from "react-toastify";
var Loader = require("react-loader");


const CreatePlan = (props) => {
    const { location, loading, getAvailableProductsForAccount, open, authReducer, setOpen, setConfirmDialog, confirmDialog, dpIds, parentPools, createCustomPlan, setEditPlanActions } = props;
    const _q = getDecodeURI(location?.search)
    const ouId = _q.hasOwnProperty("ouId") ? _q.ouId : ""
    const [poolInfo, setPoolInfo] = useState<any>(null)
    const [dpIdsToKeep, setDpIdsToKeep] = useState(dpIds)
    const [allDpsData, setAllDpsData] = useState<any>([])
    const [createPlanRows, setCreatePlanRows] = useState<any>([
        {
            planName: '',
            parentPool: "",
            recurrence: true,
            duration: 'monthly',
            planUsage: '',
            billingDate: null,
            unit: 'gb'

        },
    ]);

    const [emailInput, setEmailInput] = useState('');
    const [emails, setEmails] = useState<any>([]);
    const [error, setError] = useState('');
    const [planInfo, setPlanInfo] = useState('')
    const [entries, setEntries] = useState([{ usagePercentage: '70', type: 'sendNotification' }, { usagePercentage: '100', type: 'optOut' },]);
    const [selectedDp, setSelectedDp] = useState([])
    const handleValueChange = (index, field, value) => {
        const newEntries = [...entries];
        newEntries[index][field] = value;
        setEntries(newEntries);
    };

    const handleChangeDp = (ou: any) => {
        setSelectedDp(ou)
    }

    const handlePlanRowChange = (index, field, value) => {
        const updatedRows = createPlanRows?.map((row, i) => (
            i === index ? { ...row, [field]: value } : row
        ));
        setCreatePlanRows(updatedRows);
    };

    const handleAddEntry = () => {
        setEntries([...entries, { usagePercentage: '', type: '' }]);
    };

    const validateEmail = (email: string) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    }

    const handleAddEmail = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter' && emailInput) {
            event.preventDefault();
            if (validateEmail(emailInput)) {
                setEmails([...emails, { name: emailInput.split('@')[0].replace('.', ' ').replace(/\b\w/g, char => char.toUpperCase()), email: emailInput }]);
                setEmailInput('');
                setError('');
            } else {
                setError('Invalid email address');
            }
        }
    };

    const handleRemoveEntry = (index) => {
        const updatedEntries = entries.filter((_, i) => i !== index);
        setEntries(updatedEntries);
    };

    const handleDeleteEmail = (emailToDelete: string) => {
        setEmails(emails.filter(email => email !== emailToDelete));
    };

    const createCustomPlans = async () => {
        let res:any =  await createCustomPlan({
             credentialId: poolInfo?.credentialId,
             parentPoolId: poolInfo?.parentPoolId,
             accountNumber: poolInfo?.accountNumber,
             childPoolId: poolInfo?.parent === "No" ? poolInfo?.childPoolId : null,
             createdBy: ouId,
             planName: createPlanRows[0].planName,
             planSize: createPlanRows[0].planUsage,
             isRecurring: createPlanRows[0].recurrence,
             planInfo: planInfo,
             dpIds: selectedDp && selectedDp.length > 0 ? selectedDp?.map((dp: any) => { return dp.id; }) : [],
             duration: createPlanRows[0].duration,
             planUsage: createPlanRows[0].planUsage,
             billingDate:moment.tz().startOf('month').toISOString(),
             unit: createPlanRows[0].unit,
             usersToNotify: emails,
             entries: entries,
             planType: selectedDp && dpIds && selectedDp?.length === dpIds?.length ? "general" : "specific",
             notificationMethods: [
                 "email"
             ]
         })
         if (res?.success) {
             toast.success(`${createPlanRows[0].planName} created successfully`, {
                 position: toast.POSITION.BOTTOM_LEFT,
             })
 
         } else if (res?.response?.data?.message) {
             toast.error(`Unable to create ${createPlanRows[0].planName} `, {
                 position: toast.POSITION.BOTTOM_LEFT
             });
         }
        //  setCreatePlanDialog(false)
        //  await getCustomPlans()
        setEditPlanActions(1)
         clearData()
     }

     const clearData = () => {
        setCreatePlanRows([
            {
                planName: '',
                parentPool: "",
                recurrence: true,
                duration: 'monthly',
                planUsage: '',
                billingDate: null,
                unit: 'gb'

            },
        ])
        setPlanInfo('')
        setEmails([]);
        setEntries([{ usagePercentage: '70', type: 'sendNotification' }, { usagePercentage: '100', type: 'optOut' },]);
        setSelectedDp([])
    }
    
    const flattenPoolsData = (pools) => {
        const flatten = (pool, level = 1) => {
          const flattenedPool = {
            name: pool.poolName,
            level: level,
            accountNumber: pool.accountNumber || null,
            credentialId: pool.credentialId || null,
            parentPoolId: pool.parentPoolId || null,
            id: null,
            dpIds: []
          };
      
          let flattenedArray = [flattenedPool];
      
          const flattenPlans = (plans, parentLevel) => {
            plans.forEach((plan) => {
              flattenedArray.push({
                name: plan.planName,
                level: parentLevel + 1,
                accountNumber: pool.accountNumber || null,  
                credentialId: pool.credentialId || null,    
                parentPoolId: pool.parentPoolId || null,    
                id: plan.id,
                dpIds: plan?.organizations
              });
      
              if (plan.plans && plan.plans.length > 0) {
                flattenPlans(plan.plans, parentLevel + 1);
              }
            });
          };
      
          if (pool.plans && pool.plans.length > 0) {
            flattenPlans(pool.plans, level);
          }
      
          return flattenedArray;
        };
      
        return pools.flatMap((pool) => flatten(pool));
      };
      
      const flattenedData = flattenPoolsData(parentPools);

      useEffect(() => {
          if (!_.isEmpty(authReducer?.getDPList?.dps) && authReducer?.getDPList?.dps?.length > 0) {
              setAllDpsData(authReducer?.getDPList?.dps[0]?.parent_of)
          }
      }, [authReducer.getDPList]);


      useEffect(() => {
        setSelectedDp([])
        if (poolInfo?.level === 1) {
            setDpIdsToKeep(dpIds)
            let ou: any = dpIds?.map((d) => {
                return {
                    "id": d,
                    "name": allDpsData && allDpsData.length > 0 ? allDpsData?.find(org => org?.id === d)?.name : "",
                    "level": 2,
                    "hasChildren": false
                }
            })
            setSelectedDp(ou)

        } else  if (poolInfo?.level !== 1) {
            setDpIdsToKeep(poolInfo?.dpIds?.map((dp)=>{ return dp.dpId}))
            let ou: any = poolInfo?.dpIds?.map((dp)=>{ 
                return {
                "id": dp.dpId,
                "name": dp.dpName,
                "level": poolInfo?.level,
                "hasChildren": false
                }
            
            })
          
            setSelectedDp(ou)
        }
    }, [poolInfo])      
 
    return (
        <div className="Form">
                    <Grid item xs={12} >
                        {createPlanRows && createPlanRows?.map((row, index) => (
                            <>
                                <Grid container key={index} spacing={2} alignItems="center" style={{ marginTop: '8px' }}>

                                    <Grid item xs={2}>
                                        <Typography variant="subtitle1">Plan Name</Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Typography variant="subtitle1">Pool</Typography>
                                    </Grid>
                                    <Grid item xs={2.3}>
                                        <Typography variant="subtitle1">Organization</Typography>
                                    </Grid>
                                    <Grid item xs={1.2}>
                                        <Typography variant="subtitle1">Duration</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography variant="subtitle1">Plan Usage</Typography>
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Typography variant="subtitle1">Unit</Typography>
                                    </Grid>
                                    <Grid item xs={1.5}>
                                        <Typography variant="subtitle1">Billing Date</Typography>
                                    </Grid>
                                    <Grid item xs={0.5}>
                                        <Typography variant="subtitle1">Recurring</Typography>
                                    </Grid>
                                </Grid>

                                <Grid container spacing={2} key={index} alignItems="center">

                                    <Grid item xs={2}>
                                        <TextField
                                            fullWidth
                                            disabled={false}
                                            value={row.planName}
                                            onChange={(e) => handlePlanRowChange(index, 'planName', e.target.value)}
                                            size="small"
                                            placeholder="For eg. Custom 100GB Plan"
                                        />
                                    </Grid>

                                    <Grid item xs={2}>

                                        <Select
                                            fullWidth
                                            size="small"
                                            disabled={false}
                                            value={poolInfo ? poolInfo.id : ''} 
                                            onChange={(e) => {
                                                const selectedItem = flattenedData.find(item => item.id === e.target.value);
                                                setPoolInfo(selectedItem); 
                                            }}
                                            displayEmpty
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        maxHeight: 500, 
                                                        overflow: 'auto',  
                                                    },
                                                },
                                            }}
                                        >
                                            {flattenedData.map((item) => (
                                                <MenuItem
                                                    key={item.id}
                                                    value={item.id} 
                                                    style={{ paddingLeft: item.level * 16 }}
                                                >
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>


                                    </Grid>

                                    <Grid item xs={2.3}>
                                        <OrgSelectionMultiple
                                            multiple={true}
                                            dpIdsToKeep={dpIdsToKeep}
                                            value={selectedDp}
                                            disabled={(dpIdsToKeep && dpIdsToKeep?.length === 1) || poolInfo === null}
                                            onChange={(e) => handleChangeDp(e)} />
                                    </Grid>

                                    <Grid item xs={1.2}>
                                        <Select
                                            fullWidth
                                            disabled={false}
                                            size="small"
                                            value={row.duration}
                                            onChange={(e) => handlePlanRowChange(index, 'duration', e.target.value)}
                                            displayEmpty
                                        >
                                            <MenuItem value="" disabled>Select Duration</MenuItem>
                                            <MenuItem value="monthly">Monthly</MenuItem>
                                        </Select>
                                    </Grid>

                                    <Grid item xs={1}>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            disabled={false}
                                            value={row.planUsage}
                                            onChange={(e) => handlePlanRowChange(index, 'planUsage', e.target.value)}
                                            size="small"
                                            placeholder="100"
                                        />
                                    </Grid>

                                    <Grid item xs={1}>
                                        <Select
                                            fullWidth
                                            size="small"
                                            disabled={false}
                                            value={row.unit}
                                            onChange={(e) => handlePlanRowChange(index, 'unit', e.target.value)}
                                            displayEmpty
                                        >
                                            <MenuItem value="gb">GB</MenuItem>
                                            <MenuItem value="tb">TB</MenuItem>
                                        </Select>
                                    </Grid>

                                    <Grid item xs={1.5}>
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                disabled={false}
                                                value={momenttz().startOf('month').hours(0).minutes(0).seconds(0).milliseconds(0)}
                                                onChange={(newValue) => handlePlanRowChange(index, 'billingDate', newValue)}
                                                slots={{
                                                    textField: TextField,
                                                }}
                                                slotProps={{
                                                    textField: {
                                                        InputProps: {
                                                            style: {
                                                                height: '40px',
                                                                padding: '0 14px',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                            },
                                                            readOnly: true,
                                                        },
                                                        InputLabelProps: {
                                                            style: {
                                                                lineHeight: 'normal',
                                                            },
                                                        },
                                                    },
                                                }}
                                                readOnly
                                                disableOpenPicker
                                            />
                                        </LocalizationProvider>
                                    </Grid>

                                    <Grid item xs={0.5}>
                                        <Checkbox
                                            // disabled={false}
                                            checked={row.recurrence}
                                            onChange={(e) => handlePlanRowChange(index, 'recurrence', e.target.checked)}
                                        />
                                    </Grid>

                                </Grid>
                            </>
                        ))}
                    </Grid>

                    <Grid item xs={12} display="flex" alignItems={"center"} justifyContent="flex-start" style={{ marginTop: '10px' }}>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={1}>
                            <Typography>
                                Plan Info
                            </Typography>
                        </Grid>
                        <Grid item xs={0.5}></Grid>
                        <Grid item xs={9}>
                            <TextField
                                multiline
                                rows={3}
                                fullWidth
                                value={planInfo}
                                onChange={(e) => { setPlanInfo(e.target.value) }}
                                size="small"
                                placeholder="For eg. Some Description about plan"
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: '10px' }}>
                        {entries && entries?.map((entry, index) => (
                            <Grid
                                item
                                xs={12}
                                key={index}
                                display="flex"
                                alignItems="center"
                                justifyContent="flex-start"
                            >
                                <Grid item xs={0.4}></Grid>
                                <Grid item xs={1}>
                                    <Typography>Quota Action </Typography>
                                </Grid>
                                <Grid item xs={0.6}></Grid>
                                <Grid item xs={2}>
                                    <FormControl fullWidth>
                                        <TextField
                                            size="small"
                                            label="% used"
                                            variant="outlined"
                                            value={entry.usagePercentage}
                                            type="number"
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                if (/^\d*\.?\d*$/.test(value) && (value === "" || parseFloat(value) > 0 )) {
                                                    handleValueChange(index, 'usagePercentage', value);
                                                }
                                            }}
                                            inputProps={{
                                                inputMode: 'numeric',
                                                pattern: '[0-9]*',
                                                min: "0",
                                                step: "any"
                                            }}
                                        />

                                    </FormControl>
                                </Grid>
                                <Grid item xs={0.25}></Grid>
                                <Grid item xs={4}>
                                    <Select
                                        label="Action"
                                        fullWidth
                                        size="small"
                                        value={entry.type}
                                        onChange={(e) => handleValueChange(index, 'type', e.target.value)}
                                    >
                                        <MenuItem value="sendNotification">Send Notification</MenuItem>
                                        <MenuItem value="pause">Pause Service Line</MenuItem>
                                        <MenuItem value="optOut">Mobile Priority (Opt Out)</MenuItem>
                                    </Select>
                                </Grid>
                                <Grid item xs={0.5} display="flex" alignItems="center">
                                    {index > 1 && (
                                        <IconButton
                                            onClick={() => handleRemoveEntry(index)}
                                            color="secondary"
                                        >
                                            <DeleteIcon sx={{ color: 'red' }} fontSize="small" />
                                        </IconButton>
                                    )}
                                    {index === entries.length - 1 && (
                                        <IconButton onClick={handleAddEntry} color="primary">
                                            <AddCircleOutlineIcon />
                                        </IconButton>
                                    )}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item xs={12} display="flex" alignItems={"center"} justifyContent="flex-start" style={{ marginTop: '10px' }}>
                        <Grid item xs={0.75}></Grid>
                        <Grid item xs={1}>
                            <Typography>
                                Email
                            </Typography>
                        </Grid>
                        <Grid item xs={0.20}></Grid>
                        <Grid item xs={6.5}>
                            <Box>
                                <Paper
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexWrap: 'wrap',
                                        padding: '8px',
                                        borderRadius: '10px',
                                        border: '1px solid #c2d3eb',
                                        boxShadow: 'none',
                                    }}
                                >
                                    {emails.map((email, index) => (
                                        <Chip
                                            key={index}
                                            label={email?.email}
                                            onDelete={() => handleDeleteEmail(email)}
                                            sx={{ margin: '4px' }}
                                        />
                                    ))}
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        error={!!error}
                                        placeholder="Press Enter to add email"
                                        helperText={error}
                                        value={emailInput}
                                        onChange={(e) => setEmailInput(e.target.value)}
                                        onKeyDown={handleAddEmail}
                                        InputProps={{
                                            disableUnderline: true,
                                            style: {
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                                padding: '0 4px',
                                            },
                                        }}
                                        sx={{
                                            width: 'auto',
                                            flexGrow: 1,
                                            minWidth: '120px',
                                            '& .MuiInputBase-root': {
                                                display: 'flex',
                                                flexWrap: 'wrap',
                                            },
                                            '& .MuiInputBase-input': {
                                                padding: '8px 4px',
                                                flex: 1,
                                            },
                                        }}
                                    />
                                </Paper>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid container xs={12} display="flex" justifyContent="flex-end" style={{ marginTop: '10px',  }}>
                        <Button variant="outlined" className='starlink--button--cancel' style={{ marginRight: '10px' }} > Cancel </Button>
                        <Button variant="outlined" className='starlink--button_contained--primary'  style={{ marginRight: '10px' }} onClick={() => {  
                            createCustomPlans() 
                        }}
                            disabled={createPlanRows[0]?.planName === '' || createPlanRows[0]?.planUsage === ''}
                          
                            > Create Plan</Button>
                    </Grid>

        </div>
    )
}

const mapStateToProps = (state) => ({
    loading: state.starlinkAdmin.subcribingServiceLine,
    authReducer: state.authReducer,
    dpIds: state.starlinkCloud.dpIds,
});

export default withRouter(
    connect(mapStateToProps, {
        createCustomPlan
    })(CreatePlan)
);