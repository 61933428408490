import { withRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import ServiceAccounts from "../StarlinkAdmin/ServiceAccounts";
import ServiceLines from "../StarlinkAdmin/ServiceLines";
import PlanHierarchy from "./planHierarchy";
import AccountLinkage from "./accountLinkage";
import { useEffect, useState } from "react";
import { getPooledData } from "../StarlinkAdmin/slice";
import { getDecodeURI } from "../../utils/util";

function PooledManagement(props) {
    const {dpIds, pooledPlanData, getPooledData, location} = props;
      const _q = getDecodeURI(location?.search)
    const ouId = _q.hasOwnProperty("ouId") ? _q.ouId : ""
    const [linkageData, setLinkageData] = useState(null)
    const [hierarchyData, setHierarchyData] = useState(null)

    useEffect(()=>{
        getPooledData({
            dpIds: ouId
        })
    },[])

    useEffect(()=>{
        getPooledData({
            dpIds: ouId
        })
    },[dpIds])

   useEffect(()=>{
    if(pooledPlanData && pooledPlanData.length > 1){
        setLinkageData(pooledPlanData[0])
        setHierarchyData(pooledPlanData[1])
    }
    // else{
    //     setLinkageData(null)
    //     setHierarchyData(null)
    // }
        
   },[pooledPlanData])

    return (
        <>
            <AccountLinkage linkageData={linkageData} />
            <PlanHierarchy hierarchyData={hierarchyData} />
            <ServiceLines heading={"SERVICE LINE PLAN ASSIGNMENT"}/>
        </>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
    dpIds: state.starlinkCloud.dpIds,
    pooledPlanData: state.starlinkAdmin.pooledPlanData
});

export default withRouter(
    connect(mapStateToProps, {
        getPooledData
    })(PooledManagement)
);