import { withRouter, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import ServiceAccounts from "../StarlinkAdmin/ServiceAccounts";
import ServiceLines from "../StarlinkAdmin/ServiceLines";
import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import Download from '../../asset/image/DownloadUsage.svg';


interface AccountLinkageProps {
    linkageData:any
}

function AccountLinkage(props: AccountLinkageProps) {
    const {linkageData} = props;

    return (
        <div style={{ margin: "20px 10px" }}>
            <Grid classes={{ root: 'starlink--base--mtb_07x starlink--base--flex--align--center starlink--base--flex--justify--space_between' }}>
                <Grid>
                    <Typography component="div" classes={{ root: 'starlink--base--font_1x' }}>
                        SERVICE ACCOUNT LINKAGE
                    </Typography>
                </Grid>
                <Grid classes={{ root: 'starlink--base--flex starlink--base--flex--align--center' }}>
                    <div className="download-summary-btn" onClick={() => { }}><img className="downloadImg" src={Download} alt="" /><Button>Download</Button></div>
                </Grid>
            </Grid>

            <TableContainer component={Paper} className="starlink--table_container">
                <Table sx={{ minWidth: 650 }} size="small" aria-label="starlink--service_accounts">
                    <TableHead>
                        <TableRow>
                            <TableCell className="starlink-admin-serviceLines-action-colm">
                                <span className="font-wt-900">POOL</span>
                            </TableCell>
                            <TableCell className="starlink-admin-serviceLines-action-colm">
                                <span className="font-wt-900">STARLINK SERVICE ACCOUNT NAME</span>
                            </TableCell>
                            <TableCell className="starlink-admin-serviceLines-action-colm">
                                <span className="font-wt-900">ACCOUNT NUMBER</span>
                            </TableCell>
                            <TableCell className="starlink-admin-serviceLines-action-colm">
                                <span className="font-wt-900">SIZE</span>
                            </TableCell>
                            <TableCell className="starlink-admin-serviceLines-action-colm">
                                <span className="font-wt-900">USAGE (%)</span>
                            </TableCell>

                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                           linkageData && linkageData?.length > 0 ? linkageData.map((linkage) => {
                                return <TableRow
                                    key={linkage}
                                >
                                    <TableCell>
                                        {linkage?.poolName}
                                    </TableCell>
                                    <TableCell>
                                       {linkage?.poolName}
                                    </TableCell>
                                    <TableCell>
                                    {linkage?.accountNumber}
                                    </TableCell>
                                    <TableCell>
                                    {linkage?.poolSize} {linkage?.unit?.toUpperCase()}
                                    </TableCell>
                                    <TableCell>
                                    {linkage?.totalUsage} {linkage?.unit?.toUpperCase()} ( {(linkage?.totalUsage / linkage?.poolSize) * 100} %)
                                    </TableCell>

                                </TableRow>
                            }) : <TableRow>
                                <TableCell colSpan={8} align='center'>
                                    No data available
                                </TableCell>
                             </TableRow>
                        }
                    </TableBody>
                </Table>
            </TableContainer>


        </div>
    );
}

const mapStateToProps = (state) => ({
    authReducer: state.authReducer,
    errorReducer: state.errorReducer,
});

export default withRouter(
    connect(mapStateToProps, {})(AccountLinkage)
);