
export interface StarlinkPageProps {
    parentDpAndSubDpList: (parentDpAndSubDpList: any) => void;
    updatePermission: (permission: any) => void;
    updateSideMenu: ({ menuItem }) => void;
    setDpIds: (dpIds: string[]) => void;
    fetchUserListAPI: (ouName: string, page: number, pageSize: number, searchText: string) => void;
    validateTosAcceptance: (dpId: any) => void,
    authReducer: any;
    errorReducer: any;
    match: any;
    history: any;
    location: any;
    starlinkReports: any;
    starlinkDashboard: any;
    starlinkAdmin: any;
    starlinkToS: any;
    starlinkAnalytics: any;
    starlinkQuota: any;
    dpIds:any;
    serviceAccounts:any;
    newSummaryStartDate: any;
    newSummaryEndDate: any;
    getDpData: (dpName: any) => void;
    getServiceAccounts: (params:any) =>void;
    setServiceAccount: (params:any) =>void;
    starlinkPooled: any;
}

export type StarlinkNavTabs = "dashboard" | "usage" | "analytics" | "admin" | "quota" | "pooled" | "accounts" | "terminals" | "auditLogs" | "pooledManagemnt";

export const STARLINK_DATAUSAGE_CATEGORIES = {
    0: "Unknown",
    1: "Mobile Data",
    2: "Mobile Priority Data",
    3: "Standard Data",
    4: "Priority Data",
    5: "Non-billable"
}

export const STARLINK_DATAUSAGE_CATEGORIES_DATA: { [key: string]: string } = {
    "usageGB": "Usage",
    "usage_gb": "Usage",
    "usagePriorityGB": "Priority Data",
    "usageOptInPriorityGB": "Mobile Priority Data",
    "usageStandardGB": "Standard Data",
    "usageNonBillableGB": "Non-billable"
};