import { useEffect, useState, useMemo, useRef } from "react";
import { connect } from "react-redux";
import { useLeafletContext } from "@react-leaflet/core";
import L from "leaflet";
import './Map.css';
import "leaflet.vectorgrid";
import { SET_SELECTED_HEX } from "../../actions/actionType";

const LocationGrid = (props) => {
  const { layerContainer, map } = useLeafletContext();
  const { assets, hexOpacity, isEventsEnabled, handleHexogonClick, h3Index, setHexId, selectedHexId, zindex, resolution } = props;
  const data = assets;
  const [selectedHexLocal, setSelectedHexLocal] = useState(0);

  const hoveredHexId = useRef(null); // Track the current hex on hover

  const vectorGrid = useMemo(() => {
    return L.vectorGrid.slicer(data, {
      zIndex: zindex,
      vectorTileLayerStyles: {
        data: {
          fill: true,
          fillColor: "#FFB347",
          color: '#FFB347',
          weight: 0.3,
        },
      },
      getFeatureId: (e) => e?.properties?.id,
      interactive: true,
    });
  }, [data, hexOpacity]);

  useEffect(() => {
    const handleClick = (e) => {
      const { id, h3index } = e?.layer?.properties;
      if (isEventsEnabled) {
        h3Index(h3index);
        resetSelectedHex(selectedHexLocal);
        handleHexogonClick(e, resolution);
        setHexId(id);
        setSelectedHexLocal(id);
      }
    };

    const handleMouseOver = (e) => {
      const { id } = e.layer.properties;

      if (hoveredHexId.current === id) return; // Avoid redundant hover effect
      hoveredHexId.current = id;

      // Highlight the hexagon border color and show popup
      vectorGrid.setFeatureStyle(id, {
        color: '#FFFFFF', // White border color on hover
        weight: 1.5, // Thicker border
      });

      const popupContent = `<div>Hex ID: ${id}</div>`;
      const popup = L.popup({
        closeButton: false,
        autoClose: false,
        className: 'hex-popup',
      }).setLatLng(e.latlng).setContent(popupContent);

      map.openPopup(popup);
    };

    const handleMouseOut = (e) => {
      const { id } = e.layer.properties;
      if (hoveredHexId.current === id) {
        vectorGrid.resetFeatureStyle(id);
        map.closePopup();
        hoveredHexId.current = null;
      }
    };

    // Attach event listeners for click, mouseover, and mouseout
    vectorGrid.on("click", handleClick);
    // vectorGrid.on("mouseover", handleMouseOver);
    // vectorGrid.on("mouseout", handleMouseOut);

    return () => {
      vectorGrid.off("click", handleClick);
      vectorGrid.off("mouseover", handleMouseOver);
      vectorGrid.off("mouseout", handleMouseOut);
    };
  }, [isEventsEnabled, h3Index, selectedHexLocal, handleHexogonClick, resolution, setHexId, map]);

  const resetSelectedHex = (hexId) => {
    vectorGrid.resetFeatureStyle(hexId);
  };

  const container = layerContainer || map;

  useEffect(() => {
    container.addLayer(vectorGrid);
    return () => {
      container.removeLayer(vectorGrid);
    };
  }, [data, hexOpacity, container, vectorGrid]);

  return null;
};

const mapStateToProps = (state) => ({
  selectedHexId: state?.products?.selectedHexId
});

const mapDispatchToProps = (dispatch) => ({
  setHexId: (hexIndexId) => dispatch({ type: SET_SELECTED_HEX, item: hexIndexId })
});

export default connect(mapStateToProps, mapDispatchToProps)(LocationGrid);
